const quota_table = {
    quota: {
        title: 'Basics',
        base_price: 'Base price',
        mau_limit: 'MAU limit',
        included_tokens: 'Included tokens',
    },
    application: {
        title: 'Applications',
        total: 'Total applications',
        m2m: 'Machine-to-machine apps',
        third_party: 'OIDC/OAuth 3rd party apps',
        saml_app: 'SAML apps',
    },
    resource: {
        title: 'API resources',
        resource_count: 'Resource count',
        scopes_per_resource: 'Permissions per resource',
    },
    branding: {
        title: 'UI and branding',
        custom_domain: 'Custom domain',
        custom_css: 'Custom CSS',
        logo_and_favicon: 'Logo and favicon',
        bring_your_ui: 'Bring your UI',
        dark_mode: 'Dark mode',
        i18n: 'Internationalization',
    },
    user_authn: {
        title: 'User authentication',
        omni_sign_in: 'Omni sign-in',
        password: 'Password',
        passwordless: 'Passwordless - Email and SMS',
        email_connector: 'Email connector',
        sms_connector: 'SMS connector',
        social_connectors: 'Social connectors',
        standard_connectors: 'Standard connectors',
        built_in_email_connector: 'Built-in email connector',
        mfa: 'Multi-factor authentication',
        sso: 'Enterprise SSO',
        impersonation: 'Impersonation',
    },
    user_management: {
        title: 'User management',
        user_management: 'User management',
        roles: 'Roles',
        machine_to_machine_roles: 'Machine-to-machine roles',
        scopes_per_role: 'Permissions per role',
    },
    organizations: {
        title: 'Organizations',
        organizations: 'Organizations',
        organization: 'Organization',
        organization_count: 'Organization count',
        allowed_users_per_org: 'Users per organization',
        invitation: 'Invitation (Management API)',
        org_roles: 'Organization roles',
        org_permissions: 'Organization permissions',
        just_in_time_provisioning: 'Just-in-time provisioning',
    },
    support: {
        title: 'Support',
        community: 'Community',
        customer_ticket: 'Ticket support',
        premium: 'Premium',
        email_ticket_support: 'Email ticket support',
        discord_private_channel: 'Discord private channel',
        premium_support: 'Premium support',
        developer_onboarding: 'Developer onboarding',
        solution_engineer_support: 'Solution engineer',
        sla: 'SLA',
        dedicated_computing_resources: 'Dedicated computing resources',
    },
    compliance: {
        title: 'Compliance',
        soc2_compliant: 'SOC2 compliant',
        soc2_report: 'SOC2 report',
        hipaa_or_baa_report: 'HIPAA/BAA report',
    },
    developers_and_platform: {
        title: 'Developers and platform',
        hooks: 'Webhooks',
        audit_logs_retention: 'Audit logs retention',
        jwt_claims: 'JWT claims',
        tenant_members: 'Tenant members',
    },
    unlimited: 'Unlimited',
    contact: 'Contact',
    monthly_price: '${{value, number}}/mo',
    days: '{{count, number}} day',
    days_other: '{{count, number}} days',
    add_on: 'Add-on',
    tier: 'Tier{{value, number}}: ',
    million: '{{value, number}} million',
    mau_tip: 'MAU (monthly active user) means the number of unique users who have exchanged at least one token with Logto in a billing cycle.',
    tokens_tip: 'All kinds of tokens that issued by Logto, including access token, refresh token, etc.',
    mao_tip: 'MAO (monthly active org) means the number of unique organizations who has at least one MAU (monthly active user) in a billing cycle.',
    third_party_tip: 'Use Logto as your OIDC identity provider for third-party app sign-ins and permission grants.',
    included: '{{value, number}} included',
    included_mao: '{{value, number}} MAO included',
    extra_quota_price: 'Then ${{value, number}} per mo / ea after',
    extra_token_price: 'Then ${{value, number}} per mo / {{amount, number}} after',
    per_month_each: '${{value, number}} per mo / ea',
    extra_mao_price: 'Then ${{value, number}} per MAO',
    per_month: '${{value, number}} per mo',
    per_member: 'Then ${{value, number}} per member',
};
export default Object.freeze(quota_table);
