const users = {
    page_title: 'User management',
    title: 'User management',
    subtitle: 'Manage user identities including creating users, editing user information, viewing user logs, password resets and deleting users.',
    create: 'Add user',
    create_subtitle: 'Provide at least one of the following fields to proceed.',
    error_missing_identifier: 'You must provide at least one identifier to create a user.',
    user_name: 'User',
    application_name: 'From application',
    latest_sign_in: 'Latest sign in',
    create_form_username: 'Username',
    create_form_password: 'Password',
    create_form_name: 'Full name',
    placeholder_name: 'John/Jane Doe',
    placeholder_email: 'jdoe@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+1 (555) 555-5555',
    unnamed: 'Unnamed',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.',
};
export default Object.freeze(users);
